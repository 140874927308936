/* Generated by restful-react */

import React from 'react'
import {
  Get,
  GetProps,
  useGet,
  UseGetProps,
  Mutate,
  MutateProps,
  useMutate,
  UseMutateProps,
} from 'restful-react'

import { constants } from 'config/constants'
export const SPEC_VERSION = 'v2'
export interface ReportDto {
  id: string
  name: string
  module: string
  baseUrl: string
  attributes: ReportAttributeDto[]
}

export interface ReportAttributeDto {
  name: string
  label: string
  type: string
  options: TreeNode[]
  mandatory: boolean
  hidden: boolean
  sortOrder: number
}

export interface TreeNode {
  value: string
  label: string
  enabled: boolean
  collapsed: boolean
  childNodes: TreeNode[]
}

export interface ModelState {
  value?: ValueProviderResult
  errors?: ModelErrorCollection
}

export interface ValueProviderResult {
  attemptedValue?: string
  culture?: string
  rawValue?: {}
}

export type ModelErrorCollection = ModelError[]

export interface ModelError {
  exception?: Exception
  errorMessage?: string
}

export interface Exception {
  Message?: string
  InnerException?: Exception
  StackTrace?: string
  Source?: string
}

export interface SupervisionVisitStatusDto {
  label: string
  value: number
}

export interface SupervisionQueueVisitDto {
  /**
   * Queued start time
   */
  queuedStartTime: string
  /**
   * Start of time frame
   */
  startTime: string
  /**
   * End of time frame
   */
  stopTime: string
  serviceUserId: string
  serviceUserName: string
  serviceUserSsn: string
  alarmCode: string
  departmentName: string
  previousAttempts: SupervisionVisitAttemptDto[]
  attemptNr: number
  /**
   * True if this is the last allowed attempt
   */
  lastAttempt: boolean
  /**
   * True if any camera is offline
   */
  cameraOffline: boolean
}

export interface SupervisionVisitAttemptDto {
  time: string
  status: SupervisionStatus
  userName?: string
  note?: string
}

export type SupervisionStatus =
  | 'NotStarted'
  | 'Completed'
  | 'NotCompleted'
  | 'TechnicalIssue'
  | 'AlarmSent'

export interface SupervisionLogDto {
  visitId: string
  /**
   * Time of latest update
   */
  timeStamp: string
  startTime: string
  stopTime: string
  status: SupervisionStatus
  serviceUserId: string
  serviceUserName: string
  alarmCode?: string
  departmentName: string
  history: SupervisionVisitAttemptDto[]
}

export interface StartTimeDto {
  status: QueueStatus
  startTime?: string
}

export type QueueStatus = 'Ready' | 'Queued' | 'Pending' | 'Empty'

export interface SupervisionVisitDto {
  id: string
  personInfo: PersonInfoDto
  visitInfo: VisitInfoDto
  logs: SupervisionVisitLogDto[]
  /**
   * Visit can not be started before StartTime
   */
  startTime: string
  /**
   * Visit can not be started after StopTime
   */
  stopTime: string
  /**
   * Timer in client should stop the current visit if maxLength is reached.
   */
  maxVisitDuration: number
  cameras: LiveCameraDto[]
  statusTypes: SupervisionVisitTypeDto[]
}

export interface PersonInfoDto {
  personId: string
  personName: string
  ssn: string
  departmentId: string
  departmentName: string
  departmentPhone?: string
  alarmCode?: string
  attempt: number
  maxAttemptsAllowed: number
  camera?: LiveCameraDto
}

export type LiveCameraDto = CameraInfoDto & {
  liveStreamDto?: LiveStreamDto
  shortcutUrl?: string
}

export interface LiveStreamDto {
  macAddress: string
  acsCameraId: string
  acsServerId: string
  acsEmbeddableClientEndpoint: string
  acsWebSocketEndpoint: string
  acsToken: AccessTokenDto
}

export interface AccessTokenDto {
  accessToken: string
  expires: number
}

export interface CameraInfoDto {
  mac: string
  name: string
  cameraStatus: CameraStatus
  cameraPrivileges: CameraPrivileges[]
  ptzPositionDto?: PtzPositionDto
  audioEnabled: boolean
  isContinuous: boolean
  ledEnabled: boolean
  personId: string
  supervisionVisitId: string
}

export type CameraStatus = 'NoStatus' | 'Ok' | 'Unauthenticated' | 'NotAccessible'

export type CameraPrivileges =
  | 'NoPrivileges'
  | 'LiveViewAccess'
  | 'PlaybackAccess'
  | 'PtzAccess'
  | 'AudioInAccess'
  | 'AudioOutAccess'

export interface PtzPositionDto {
  minPan: number
  maxPan: number
  pan: number
  minTilt: number
  maxTilt: number
  tilt: number
  minZoom: number
  maxZoom: number
  zoom: number
}

export interface VisitInfoDto {
  patientInfo?: string
  providerInfo?: string
  noteRequired: boolean
  noteRequiredWhenFailed: boolean
}

export interface SupervisionVisitLogDto {
  status: string
  text?: string
  personnelFullName: string
  actualStartTime: string
  actualStopTime: string
}

export interface SupervisionVisitTypeDto {
  value: string
  label: string
  noteRequired: boolean
}

export interface StartVisitRequest {
  supervisionVisitId: string
}

export interface SaveSupervisionVisitRequest {
  supervisionVisitId: string
  supervisionVisitTypeId: string
  noteText?: string
}

export interface SaveStatusRequest {
  status: number
  supervisionVisitIds: string[]
}

export interface ContinuousVisitDto {
  id: string
  personInfo: PersonInfoDto
  visitInfo: VisitInfoDto
  maxVisitDuration: number
  cameras: LiveCameraDto[]
  logs: SupervisionVisitLogDto[]
  statusTypes: SupervisionVisitTypeDto[]
}

export interface StartContinuousVisitRequest {
  personId: string
  visitId: string
}

export interface StopContinuousVisitRequest {
  personId: string
  visitId: string
  supervisionVisitTypeId: string
  noteText?: string
}

export interface AlarmResponseCenterDto {
  id: string
  name?: string
  protocol?: string
  address?: string
}

export interface CreateAlarmResponseCenterRequest {
  name: string
  protocol: ArcProtocol
  address: string
}

export type ArcProtocol = 1 | 2

export interface PersonCameraDto {
  personId: string
  ssn: string
  personName: string
  camera: LiveCameraDto
}

export interface PtzCapabilitiesDto {
  mac: string
  ptzPanMin: number
  ptzPanMax: number
  ptzPanPosition: number
  ptzTiltMin: number
  ptzTiltMax: number
  ptzTiltPosition: number
  ptzZoomMin: number
  ptzZoomMax: number
  ptzZoomPosition: number
}

export interface PtzPositionDto2 {
  mac: string
  ptzPanPosition: number
  ptzTiltPosition: number
  ptzZoomPosition: number
}

export interface PtzRequest {
  mac: string
  pan: number
  tilt: number
  zoom: number
}

export interface FacilityDto {
  id: string
  name: string
}

export interface ActivityMonitoringPersonDto {
  personId: string
  name: string
  apartmentName: string
}

export type ExtendedActivityMonitoringPersonDto = ActivityMonitoringPersonDto & {
  facilityId: string
  facilityName: string
}

export interface DM90TokenDto {
  id: string
  name?: string
  identifier: string
  token?: string
  blockedDate?: string
  createdDate: string
  lastConnectedDate?: string
  departmentId?: string
  departmentName?: string
}

export interface CreateDM90TokenDto {
  name?: string
  identifier: string
  departmentId?: string
}

export interface DM90TokenDepartmentDto {
  id: string
  name?: string
}

export interface PersonDto {
  id: string
  lastName?: string
  firstName?: string
  ssn?: string
  address?: PersonAddressDto
}

export interface PersonAddressDto {
  streetAddress?: string
  city?: string
  zipCode?: string
  latitude: number
  longitude: number
}

export interface RtmStatusDto {
  fromTime: string
  toTime: string
  tracks: TimeWindowTrackDto[]
  averageVisitTimeInSeconds: number
  totalInQueue: number
  totalPerformed: number
  visitsInCurrent: number
  performedInCurrent: number
  activeFtes: FteDto[]
  ftesNeeded: number
  timeAvailableInSeconds: number
  secondsLeftInActiveWindows: number
}

export interface TimeWindowTrackDto {
  timeWindows: TimeWindowDto[]
  totalVisitDuration: number
}

export interface TimeWindowDto {
  id: string
  startTime: string
  stopTime: string
  totalVisits: number
  totalVisitsPerformed: number
  totalVisitsStarted: number
  totalVisitsSkipped: number
  timeLagPercentage: number
  actualStartTime: string
  timeAvailableInMinutes: number
  visitIds: string[]
}

export interface FteDto {
  id: string
  name: string
}

export interface SupervisionFailureDto {
  departmentName: string
  departmentId: string
  emailAddress?: string
  phoneNumber?: string
  failures: FailureDto[]
}

export interface FailureDto {
  logId?: number
  supervisionVisitId: string
  startedAt: string
  personInitials: string
  alarmCode: string
  cameraName: string
  cameraMac: string
  logMessage?: string
  exceptionComment?: string
  visitId: string
}

export interface SimpleCameraVisitDto {
  plannedTime: string
  person: PersonInfoDto
}

export interface SystemInfoDto {
  helpManualBasePath: string
  tesVersion: string
  topDepartmentName: string
  language: string
  parameters: ParametersDto
}

export interface ParametersDto {
  googleMapsApiKey?: string
  timeZone?: string
  tesUiUrl?: string
  videoCallServiceUrl?: string
  videoCallNotificationServiceUrl?: string
  positioningServiceUrl?: string
}

export interface UserDto {
  personnelId: string
  firstName: string
  lastName: string
  isExternal: boolean
  authType: number
  homeUrl: string
  modules: ModuleDto[]
  roles: RoleDto[]
  permissions: string[]
}

export interface ModuleDto {
  name: string
  url: string
  isAdmin: boolean
  sortOrder: number
}

export interface RoleDto {
  roleName: string
  departmentName: string
}

export interface PersonnelInfo {
  guid: string
  name: string
  ssn?: string
}

export interface ChangePasswordRequest {
  currentPassword: string
  newPassword: string
}

export interface VideoCallsSettingsDto {
  /**
   * Gets or sets the vidyo.io application ID.
   */
  vidyoApplicationId: string
  /**
   * Gets or sets the vidyo.io developer key.
   */
  vidyoDeveloperKey: string
  /**
   * Gets or sets the vidyo.io host.
   */
  vidyoHost: string
}

/**
 * The information about a Person needed for a video call.
 */
export interface VideoCallPersonDto {
  /**
   * Gets or sets the Person Id.
   */
  personId: string
  /**
   * Gets or sets the name of the Person.
   */
  name: string
}

/**
 * The information about a Personnel needed for a video call.
 */
export interface VideoCallPersonnelDto {
  /**
   * Gets or sets the Personnel Id.
   */
  personnelId: string
  /**
   * Gets or sets the name of the Personnel.
   */
  name: string
}

export interface GetReportMetaDataQueryParams {
  id: string
}

export type GetReportMetaDataProps = Omit<
  GetProps<ReportDto, ModelState, GetReportMetaDataQueryParams, void>,
  'path'
>

export const GetReportMetaData = (props: GetReportMetaDataProps) => (
  <Get<ReportDto, ModelState, GetReportMetaDataQueryParams, void>
    path={`/api/v2/reports/meta`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetReportMetaDataProps = Omit<
  UseGetProps<ReportDto, ModelState, GetReportMetaDataQueryParams, void>,
  'path'
>

export const useGetReportMetaData = (props: UseGetReportMetaDataProps) =>
  useGet<ReportDto, ModelState, GetReportMetaDataQueryParams, void>(`/api/v2/reports/meta`, {
    base: constants.tesWebUrl,
    ...props,
  })

export type AllReportsProps = Omit<GetProps<ReportDto[], ModelState, void, void>, 'path'>

export const AllReports = (props: AllReportsProps) => (
  <Get<ReportDto[], ModelState, void, void>
    path={`/api/v2/reports/all`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseAllReportsProps = Omit<UseGetProps<ReportDto[], ModelState, void, void>, 'path'>

export const useAllReports = (props: UseAllReportsProps) =>
  useGet<ReportDto[], ModelState, void, void>(`/api/v2/reports/all`, {
    base: constants.tesWebUrl,
    ...props,
  })

export type ProxyProps = Omit<GetProps<string, unknown, void, void>, 'path'>

export const Proxy = (props: ProxyProps) => (
  <Get<string, unknown, void, void>
    path={`/api/v2/reports/proxy`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseProxyProps = Omit<UseGetProps<string, unknown, void, void>, 'path'>

export const useProxy = (props: UseProxyProps) =>
  useGet<string, unknown, void, void>(`/api/v2/reports/proxy`, {
    base: constants.tesWebUrl,
    ...props,
  })

export type VisitStatusesProps = Omit<
  GetProps<SupervisionVisitStatusDto[], ModelState, void, void>,
  'path'
>

/**
 * Returns supervision visit status types that a client can set directly on a supervision visit.
 */
export const VisitStatuses = (props: VisitStatusesProps) => (
  <Get<SupervisionVisitStatusDto[], ModelState, void, void>
    path={`/api/v2/supervision/visitstatuses`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseVisitStatusesProps = Omit<
  UseGetProps<SupervisionVisitStatusDto[], ModelState, void, void>,
  'path'
>

/**
 * Returns supervision visit status types that a client can set directly on a supervision visit.
 */
export const useVisitStatuses = (props: UseVisitStatusesProps) =>
  useGet<SupervisionVisitStatusDto[], ModelState, void, void>(`/api/v2/supervision/visitstatuses`, {
    base: constants.tesWebUrl,
    ...props,
  })

export type GetVisitQueueProps = Omit<
  GetProps<SupervisionQueueVisitDto[], unknown, void, void>,
  'path'
>

/**
 * Get queued visits
 */
export const GetVisitQueue = (props: GetVisitQueueProps) => (
  <Get<SupervisionQueueVisitDto[], unknown, void, void>
    path={`/api/v2/supervision/visitqueue`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetVisitQueueProps = Omit<
  UseGetProps<SupervisionQueueVisitDto[], unknown, void, void>,
  'path'
>

/**
 * Get queued visits
 */
export const useGetVisitQueue = (props: UseGetVisitQueueProps) =>
  useGet<SupervisionQueueVisitDto[], unknown, void, void>(`/api/v2/supervision/visitqueue`, {
    base: constants.tesWebUrl,
    ...props,
  })

export interface GetVisitLogQueryParams {
  fromDate: string
  toDate: string
}

export type GetVisitLogProps = Omit<
  GetProps<SupervisionLogDto[], unknown, GetVisitLogQueryParams, void>,
  'path'
>

/**
 * Get visit log
 */
export const GetVisitLog = (props: GetVisitLogProps) => (
  <Get<SupervisionLogDto[], unknown, GetVisitLogQueryParams, void>
    path={`/api/v2/supervision/visitlog`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetVisitLogProps = Omit<
  UseGetProps<SupervisionLogDto[], unknown, GetVisitLogQueryParams, void>,
  'path'
>

/**
 * Get visit log
 */
export const useGetVisitLog = (props: UseGetVisitLogProps) =>
  useGet<SupervisionLogDto[], unknown, GetVisitLogQueryParams, void>(
    `/api/v2/supervision/visitlog`,
    { base: constants.tesWebUrl, ...props }
  )

export type GetNumberOfMissedProps = Omit<GetProps<number, unknown, void, void>, 'path'>

export const GetNumberOfMissed = (props: GetNumberOfMissedProps) => (
  <Get<number, unknown, void, void>
    path={`/api/v2/supervision/checkmissed`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetNumberOfMissedProps = Omit<UseGetProps<number, unknown, void, void>, 'path'>

export const useGetNumberOfMissed = (props: UseGetNumberOfMissedProps) =>
  useGet<number, unknown, void, void>(`/api/v2/supervision/checkmissed`, {
    base: constants.tesWebUrl,
    ...props,
  })

export type StartTimeProps = Omit<GetProps<StartTimeDto, ModelState, void, void>, 'path'>

/**
 * Returns the start time of the next planned supervision visit.
 */
export const StartTime = (props: StartTimeProps) => (
  <Get<StartTimeDto, ModelState, void, void>
    path={`/api/v2/supervision/starttime`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseStartTimeProps = Omit<UseGetProps<StartTimeDto, ModelState, void, void>, 'path'>

/**
 * Returns the start time of the next planned supervision visit.
 */
export const useStartTime = (props: UseStartTimeProps) =>
  useGet<StartTimeDto, ModelState, void, void>(`/api/v2/supervision/starttime`, {
    base: constants.tesWebUrl,
    ...props,
  })

export interface ReserveNextVisitsQueryParams {
  /**
   * Number of requested visits. Must be greater than 0 and less than 3.
   */
  numVisits: number
  /**
   * Indicates if the user has refreshed(F5, Effafemma) the queue page and should release the queue(F5)
   */
  queueStarted: boolean
  shortcut: boolean
}

export type ReserveNextVisitsProps = Omit<
  MutateProps<SupervisionVisitDto[], ModelState, ReserveNextVisitsQueryParams, void, void>,
  'path' | 'verb'
>

/**
 * Returns the next queued supervision visit(s).
 */
export const ReserveNextVisits = (props: ReserveNextVisitsProps) => (
  <Mutate<SupervisionVisitDto[], ModelState, ReserveNextVisitsQueryParams, void, void>
    verb="PUT"
    path={`/api/v2/supervision/reservevisits`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseReserveNextVisitsProps = Omit<
  UseMutateProps<SupervisionVisitDto[], ModelState, ReserveNextVisitsQueryParams, void, void>,
  'path' | 'verb'
>

/**
 * Returns the next queued supervision visit(s).
 */
export const useReserveNextVisits = (props: UseReserveNextVisitsProps) =>
  useMutate<SupervisionVisitDto[], ModelState, ReserveNextVisitsQueryParams, void, void>(
    'PUT',
    `/api/v2/supervision/reservevisits`,
    { base: constants.tesWebUrl, ...props }
  )

export type StartVisitProps = Omit<
  MutateProps<void, ModelState, void, StartVisitRequest, void>,
  'path' | 'verb'
>

/**
 * Called when a supervision visit starts, i.e. when the video becomes visible.
 */
export const StartVisit = (props: StartVisitProps) => (
  <Mutate<void, ModelState, void, StartVisitRequest, void>
    verb="POST"
    path={`/api/v2/supervision/startvisit`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseStartVisitProps = Omit<
  UseMutateProps<void, ModelState, void, StartVisitRequest, void>,
  'path' | 'verb'
>

/**
 * Called when a supervision visit starts, i.e. when the video becomes visible.
 */
export const useStartVisit = (props: UseStartVisitProps) =>
  useMutate<void, ModelState, void, StartVisitRequest, void>(
    'POST',
    `/api/v2/supervision/startvisit`,
    { base: constants.tesWebUrl, ...props }
  )

export type SaveProps = Omit<
  MutateProps<void, ModelState | void, void, SaveSupervisionVisitRequest, void>,
  'path' | 'verb'
>

/**
 * Saves a visit with status 'Performed'.
 * An operator must submit a status type for the visit to be saved.
 * A status type can have any description / label and have one of three categories:
 * 'Successful' (successful visit, visit will be dequeued),
 * 'Unsuccessful' (unsuccessful visit, visit will not be dequeued),
 * 'Failed' (unsuccessful visit, visit will be dequeued).
 * There are two default types for the 'Filaed' category: Alarmed and Technical failure.
 * All categories except 'Unsuccessful' will dequeue the visit, meaning that there will be no more attempts available for that particular visit.
 * An optional text / note can be supplied as an additional clarification or specification of the result.
 */
export const Save = (props: SaveProps) => (
  <Mutate<void, ModelState | void, void, SaveSupervisionVisitRequest, void>
    verb="POST"
    path={`/api/v2/supervision/save`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseSaveProps = Omit<
  UseMutateProps<void, ModelState | void, void, SaveSupervisionVisitRequest, void>,
  'path' | 'verb'
>

/**
 * Saves a visit with status 'Performed'.
 * An operator must submit a status type for the visit to be saved.
 * A status type can have any description / label and have one of three categories:
 * 'Successful' (successful visit, visit will be dequeued),
 * 'Unsuccessful' (unsuccessful visit, visit will not be dequeued),
 * 'Failed' (unsuccessful visit, visit will be dequeued).
 * There are two default types for the 'Filaed' category: Alarmed and Technical failure.
 * All categories except 'Unsuccessful' will dequeue the visit, meaning that there will be no more attempts available for that particular visit.
 * An optional text / note can be supplied as an additional clarification or specification of the result.
 */
export const useSave = (props: UseSaveProps) =>
  useMutate<void, ModelState | void, void, SaveSupervisionVisitRequest, void>(
    'POST',
    `/api/v2/supervision/save`,
    { base: constants.tesWebUrl, ...props }
  )

export type SaveStatusProps = Omit<
  MutateProps<void, ModelState | void, void, SaveStatusRequest, void>,
  'path' | 'verb'
>

/**
 * This method can be called when an operator or administrator e.g. propmt needs to
 * either mark the visits as 'Performed' or leave it as 'Skipped' or 'Unassigned'.
 * (A skipped visit means that the attempt was void / invalid,
 * and the visit will be ready again in the queue without any delay, e.g. when an operator leaves the queue.)
 * Status 'Unassigned' can be set to immediately unlock visits that
 * have been stuck in the 'Pending' or 'Started' states due to a system failure or
 * incorrect closing of a client application.
 */
export const SaveStatus = (props: SaveStatusProps) => (
  <Mutate<void, ModelState | void, void, SaveStatusRequest, void>
    verb="POST"
    path={`/api/v2/supervision/savestatus`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseSaveStatusProps = Omit<
  UseMutateProps<void, ModelState | void, void, SaveStatusRequest, void>,
  'path' | 'verb'
>

/**
 * This method can be called when an operator or administrator e.g. propmt needs to
 * either mark the visits as 'Performed' or leave it as 'Skipped' or 'Unassigned'.
 * (A skipped visit means that the attempt was void / invalid,
 * and the visit will be ready again in the queue without any delay, e.g. when an operator leaves the queue.)
 * Status 'Unassigned' can be set to immediately unlock visits that
 * have been stuck in the 'Pending' or 'Started' states due to a system failure or
 * incorrect closing of a client application.
 */
export const useSaveStatus = (props: UseSaveStatusProps) =>
  useMutate<void, ModelState | void, void, SaveStatusRequest, void>(
    'POST',
    `/api/v2/supervision/savestatus`,
    { base: constants.tesWebUrl, ...props }
  )

export interface GetContinuousInfoQueryParams {
  personid: string
}

export type GetContinuousInfoProps = Omit<
  GetProps<ContinuousVisitDto, ModelState, GetContinuousInfoQueryParams, void>,
  'path'
>

export const GetContinuousInfo = (props: GetContinuousInfoProps) => (
  <Get<ContinuousVisitDto, ModelState, GetContinuousInfoQueryParams, void>
    path={`/api/v2/supervision/continuous/info`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetContinuousInfoProps = Omit<
  UseGetProps<ContinuousVisitDto, ModelState, GetContinuousInfoQueryParams, void>,
  'path'
>

export const useGetContinuousInfo = (props: UseGetContinuousInfoProps) =>
  useGet<ContinuousVisitDto, ModelState, GetContinuousInfoQueryParams, void>(
    `/api/v2/supervision/continuous/info`,
    { base: constants.tesWebUrl, ...props }
  )

export interface GetContinuousShortcutInfoQueryParams {
  personid: string
}

export type GetContinuousShortcutInfoProps = Omit<
  GetProps<ContinuousVisitDto, ModelState, GetContinuousShortcutInfoQueryParams, void>,
  'path'
>

export const GetContinuousShortcutInfo = (props: GetContinuousShortcutInfoProps) => (
  <Get<ContinuousVisitDto, ModelState, GetContinuousShortcutInfoQueryParams, void>
    path={`/api/v2/supervision/continuous/info/shortcut`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetContinuousShortcutInfoProps = Omit<
  UseGetProps<ContinuousVisitDto, ModelState, GetContinuousShortcutInfoQueryParams, void>,
  'path'
>

export const useGetContinuousShortcutInfo = (props: UseGetContinuousShortcutInfoProps) =>
  useGet<ContinuousVisitDto, ModelState, GetContinuousShortcutInfoQueryParams, void>(
    `/api/v2/supervision/continuous/info/shortcut`,
    { base: constants.tesWebUrl, ...props }
  )

export type StartContinuousProps = Omit<
  MutateProps<void, ModelState, void, StartContinuousVisitRequest, void>,
  'path' | 'verb'
>

export const StartContinuous = (props: StartContinuousProps) => (
  <Mutate<void, ModelState, void, StartContinuousVisitRequest, void>
    verb="POST"
    path={`/api/v2/supervision/continuous/start`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseStartContinuousProps = Omit<
  UseMutateProps<void, ModelState, void, StartContinuousVisitRequest, void>,
  'path' | 'verb'
>

export const useStartContinuous = (props: UseStartContinuousProps) =>
  useMutate<void, ModelState, void, StartContinuousVisitRequest, void>(
    'POST',
    `/api/v2/supervision/continuous/start`,
    { base: constants.tesWebUrl, ...props }
  )

export type StopContinuousProps = Omit<
  MutateProps<void, ModelState, void, StopContinuousVisitRequest, void>,
  'path' | 'verb'
>

export const StopContinuous = (props: StopContinuousProps) => (
  <Mutate<void, ModelState, void, StopContinuousVisitRequest, void>
    verb="POST"
    path={`/api/v2/supervision/continuous/stop`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseStopContinuousProps = Omit<
  UseMutateProps<void, ModelState, void, StopContinuousVisitRequest, void>,
  'path' | 'verb'
>

export const useStopContinuous = (props: UseStopContinuousProps) =>
  useMutate<void, ModelState, void, StopContinuousVisitRequest, void>(
    'POST',
    `/api/v2/supervision/continuous/stop`,
    { base: constants.tesWebUrl, ...props }
  )

export type ListAlarmResponseCentersProps = Omit<
  GetProps<AlarmResponseCenterDto[], unknown, void, void>,
  'path'
>

export const ListAlarmResponseCenters = (props: ListAlarmResponseCentersProps) => (
  <Get<AlarmResponseCenterDto[], unknown, void, void>
    path={`/api/v2/alarmresponsecenter`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseListAlarmResponseCentersProps = Omit<
  UseGetProps<AlarmResponseCenterDto[], unknown, void, void>,
  'path'
>

export const useListAlarmResponseCenters = (props: UseListAlarmResponseCentersProps) =>
  useGet<AlarmResponseCenterDto[], unknown, void, void>(`/api/v2/alarmresponsecenter`, {
    base: constants.tesWebUrl,
    ...props,
  })

export type CreateAlarmResponseCenterProps = Omit<
  MutateProps<AlarmResponseCenterDto, ModelState, void, CreateAlarmResponseCenterRequest, void>,
  'path' | 'verb'
>

export const CreateAlarmResponseCenter = (props: CreateAlarmResponseCenterProps) => (
  <Mutate<AlarmResponseCenterDto, ModelState, void, CreateAlarmResponseCenterRequest, void>
    verb="POST"
    path={`/api/v2/alarmresponsecenter`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseCreateAlarmResponseCenterProps = Omit<
  UseMutateProps<AlarmResponseCenterDto, ModelState, void, CreateAlarmResponseCenterRequest, void>,
  'path' | 'verb'
>

export const useCreateAlarmResponseCenter = (props: UseCreateAlarmResponseCenterProps) =>
  useMutate<AlarmResponseCenterDto, ModelState, void, CreateAlarmResponseCenterRequest, void>(
    'POST',
    `/api/v2/alarmresponsecenter`,
    { base: constants.tesWebUrl, ...props }
  )

export interface DeleteAlarmResponseCenterQueryParams {
  request: string
}

export type DeleteAlarmResponseCenterProps = Omit<
  MutateProps<void, void, DeleteAlarmResponseCenterQueryParams, void, void>,
  'path' | 'verb'
>

export const DeleteAlarmResponseCenter = (props: DeleteAlarmResponseCenterProps) => (
  <Mutate<void, void, DeleteAlarmResponseCenterQueryParams, void, void>
    verb="DELETE"
    path={`/api/v2/alarmresponsecenter`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseDeleteAlarmResponseCenterProps = Omit<
  UseMutateProps<void, void, DeleteAlarmResponseCenterQueryParams, void, void>,
  'path' | 'verb'
>

export const useDeleteAlarmResponseCenter = (props: UseDeleteAlarmResponseCenterProps) =>
  useMutate<void, void, DeleteAlarmResponseCenterQueryParams, void, void>(
    'DELETE',
    `/api/v2/alarmresponsecenter`,
    { base: constants.tesWebUrl, ...props }
  )

export interface PersonInfoQueryParams {
  mac: string
  personId: string
  isShortcut: boolean
}

export type PersonInfoProps = Omit<
  GetProps<PersonCameraDto, ModelState, PersonInfoQueryParams, void>,
  'path'
>

export const PersonInfo = (props: PersonInfoProps) => (
  <Get<PersonCameraDto, ModelState, PersonInfoQueryParams, void>
    path={`/api/v2/camera/personcamera`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UsePersonInfoProps = Omit<
  UseGetProps<PersonCameraDto, ModelState, PersonInfoQueryParams, void>,
  'path'
>

export const usePersonInfo = (props: UsePersonInfoProps) =>
  useGet<PersonCameraDto, ModelState, PersonInfoQueryParams, void>(`/api/v2/camera/personcamera`, {
    base: constants.tesWebUrl,
    ...props,
  })

export interface PtzPositionsQueryParams {
  /**
   * Array of camera mac addresses. At least one mac is required.
   */
  macs?: string[]
}

export type PtzPositionsProps = Omit<
  GetProps<PtzCapabilitiesDto[], ModelState, PtzPositionsQueryParams, void>,
  'path'
>

/**
 * Get current PTZ positions for one or more cameras.
 */
export const PtzPositions = (props: PtzPositionsProps) => (
  <Get<PtzCapabilitiesDto[], ModelState, PtzPositionsQueryParams, void>
    path={`/api/v2/camera/ptz/positions`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UsePtzPositionsProps = Omit<
  UseGetProps<PtzCapabilitiesDto[], ModelState, PtzPositionsQueryParams, void>,
  'path'
>

/**
 * Get current PTZ positions for one or more cameras.
 */
export const usePtzPositions = (props: UsePtzPositionsProps) =>
  useGet<PtzCapabilitiesDto[], ModelState, PtzPositionsQueryParams, void>(
    `/api/v2/camera/ptz/positions`,
    { base: constants.tesWebUrl, ...props }
  )

export interface UpdateVhsCamerasQueryParams {
  departmentId: string
}

export type UpdateVhsCamerasProps = Omit<
  MutateProps<void, ModelState, UpdateVhsCamerasQueryParams, void, void>,
  'path' | 'verb'
>

/**
 * Update missing cameras in vhs per department and accessKey
 */
export const UpdateVhsCameras = (props: UpdateVhsCamerasProps) => (
  <Mutate<void, ModelState, UpdateVhsCamerasQueryParams, void, void>
    verb="POST"
    path={`/api/v2/camera/updatevhscameras`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseUpdateVhsCamerasProps = Omit<
  UseMutateProps<void, ModelState, UpdateVhsCamerasQueryParams, void, void>,
  'path' | 'verb'
>

/**
 * Update missing cameras in vhs per department and accessKey
 */
export const useUpdateVhsCameras = (props: UseUpdateVhsCamerasProps) =>
  useMutate<void, ModelState, UpdateVhsCamerasQueryParams, void, void>(
    'POST',
    `/api/v2/camera/updatevhscameras`,
    { base: constants.tesWebUrl, ...props }
  )

export interface MoveToPresetQueryParams {
  /**
   * The camera mac address.
   */
  mac: string
  /**
   * True, if move to home position.
   */
  home: boolean
}

export type MoveToPresetProps = Omit<
  GetProps<PtzPositionDto2, ModelState, MoveToPresetQueryParams, void>,
  'path'
>

/**
 * Move to PTZ preset (home or active) position
 */
export const MoveToPreset = (props: MoveToPresetProps) => (
  <Get<PtzPositionDto2, ModelState, MoveToPresetQueryParams, void>
    path={`/api/v2/camera/ptz/movetohome`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseMoveToPresetProps = Omit<
  UseGetProps<PtzPositionDto2, ModelState, MoveToPresetQueryParams, void>,
  'path'
>

/**
 * Move to PTZ preset (home or active) position
 */
export const useMoveToPreset = (props: UseMoveToPresetProps) =>
  useGet<PtzPositionDto2, ModelState, MoveToPresetQueryParams, void>(
    `/api/v2/camera/ptz/movetohome`,
    { base: constants.tesWebUrl, ...props }
  )

export type MoveToPtzProps = Omit<
  MutateProps<void, ModelState, void, PtzRequest, void>,
  'path' | 'verb'
>

/**
 * Moves the camera to the requested position and zoom level. The camera must have a live stream
 */
export const MoveToPtz = (props: MoveToPtzProps) => (
  <Mutate<void, ModelState, void, PtzRequest, void>
    verb="POST"
    path={`/api/v2/camera/ptz`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseMoveToPtzProps = Omit<
  UseMutateProps<void, ModelState, void, PtzRequest, void>,
  'path' | 'verb'
>

/**
 * Moves the camera to the requested position and zoom level. The camera must have a live stream
 */
export const useMoveToPtz = (props: UseMoveToPtzProps) =>
  useMutate<void, ModelState, void, PtzRequest, void>('POST', `/api/v2/camera/ptz`, {
    base: constants.tesWebUrl,
    ...props,
  })

export interface SaveHomePresetQueryParams {
  mac: string
}

export type SaveHomePresetProps = Omit<
  MutateProps<void, ModelState | void, SaveHomePresetQueryParams, void, void>,
  'path' | 'verb'
>

export const SaveHomePreset = (props: SaveHomePresetProps) => (
  <Mutate<void, ModelState | void, SaveHomePresetQueryParams, void, void>
    verb="POST"
    path={`/api/v2/camera/ptz/home`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseSaveHomePresetProps = Omit<
  UseMutateProps<void, ModelState | void, SaveHomePresetQueryParams, void, void>,
  'path' | 'verb'
>

export const useSaveHomePreset = (props: UseSaveHomePresetProps) =>
  useMutate<void, ModelState | void, SaveHomePresetQueryParams, void, void>(
    'POST',
    `/api/v2/camera/ptz/home`,
    { base: constants.tesWebUrl, ...props }
  )

export interface SaveActivePresetQueryParams {
  mac: string
}

export type SaveActivePresetProps = Omit<
  MutateProps<void, ModelState | void, SaveActivePresetQueryParams, void, void>,
  'path' | 'verb'
>

export const SaveActivePreset = (props: SaveActivePresetProps) => (
  <Mutate<void, ModelState | void, SaveActivePresetQueryParams, void, void>
    verb="POST"
    path={`/api/v2/camera/ptz/active`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseSaveActivePresetProps = Omit<
  UseMutateProps<void, ModelState | void, SaveActivePresetQueryParams, void, void>,
  'path' | 'verb'
>

export const useSaveActivePreset = (props: UseSaveActivePresetProps) =>
  useMutate<void, ModelState | void, SaveActivePresetQueryParams, void, void>(
    'POST',
    `/api/v2/camera/ptz/active`,
    { base: constants.tesWebUrl, ...props }
  )

export interface GetLiveCameraQueryParams {
  mac: string
  personId: string
  action?: string
}

export type GetLiveCameraProps = Omit<
  GetProps<LiveCameraDto, ModelState | void, GetLiveCameraQueryParams, void>,
  'path'
>

export const GetLiveCamera = (props: GetLiveCameraProps) => (
  <Get<LiveCameraDto, ModelState | void, GetLiveCameraQueryParams, void>
    path={`/api/v2/camera/live`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetLiveCameraProps = Omit<
  UseGetProps<LiveCameraDto, ModelState | void, GetLiveCameraQueryParams, void>,
  'path'
>

export const useGetLiveCamera = (props: UseGetLiveCameraProps) =>
  useGet<LiveCameraDto, ModelState | void, GetLiveCameraQueryParams, void>(`/api/v2/camera/live`, {
    base: constants.tesWebUrl,
    ...props,
  })

export interface GetShortcutLiveCameraQueryParams {
  mac: string
  personId: string
  action?: string
}

export type GetShortcutLiveCameraProps = Omit<
  GetProps<LiveCameraDto, ModelState | void, GetShortcutLiveCameraQueryParams, void>,
  'path'
>

export const GetShortcutLiveCamera = (props: GetShortcutLiveCameraProps) => (
  <Get<LiveCameraDto, ModelState | void, GetShortcutLiveCameraQueryParams, void>
    path={`/api/v2/camera/live/shortcut`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetShortcutLiveCameraProps = Omit<
  UseGetProps<LiveCameraDto, ModelState | void, GetShortcutLiveCameraQueryParams, void>,
  'path'
>

export const useGetShortcutLiveCamera = (props: UseGetShortcutLiveCameraProps) =>
  useGet<LiveCameraDto, ModelState | void, GetShortcutLiveCameraQueryParams, void>(
    `/api/v2/camera/live/shortcut`,
    { base: constants.tesWebUrl, ...props }
  )

export interface CloseCameraQueryParams {
  mac: string
  personGuid: string
}

export type CloseCameraProps = Omit<
  MutateProps<void, ModelState, CloseCameraQueryParams, void, void>,
  'path' | 'verb'
>

export const CloseCamera = (props: CloseCameraProps) => (
  <Mutate<void, ModelState, CloseCameraQueryParams, void, void>
    verb="POST"
    path={`/api/v2/camera/close`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseCloseCameraProps = Omit<
  UseMutateProps<void, ModelState, CloseCameraQueryParams, void, void>,
  'path' | 'verb'
>

export const useCloseCamera = (props: UseCloseCameraProps) =>
  useMutate<void, ModelState, CloseCameraQueryParams, void, void>('POST', `/api/v2/camera/close`, {
    base: constants.tesWebUrl,
    ...props,
  })

export type AmsGetAllFacilitiesProps = Omit<GetProps<FacilityDto[], void, void, void>, 'path'>

/**
 * Get all facilities.
 */
export const AmsGetAllFacilities = (props: AmsGetAllFacilitiesProps) => (
  <Get<FacilityDto[], void, void, void>
    path={`/api/v2/activitymonitoring/facilities`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseAmsGetAllFacilitiesProps = Omit<UseGetProps<FacilityDto[], void, void, void>, 'path'>

/**
 * Get all facilities.
 */
export const useAmsGetAllFacilities = (props: UseAmsGetAllFacilitiesProps) =>
  useGet<FacilityDto[], void, void, void>(`/api/v2/activitymonitoring/facilities`, {
    base: constants.tesWebUrl,
    ...props,
  })

export interface AmsGetFacilitiesByPersonnelPathParams {
  /**
   * The personnel.
   */
  personnelId: string
}

export type AmsGetFacilitiesByPersonnelProps = Omit<
  GetProps<FacilityDto[], void, void, AmsGetFacilitiesByPersonnelPathParams>,
  'path'
> &
  AmsGetFacilitiesByPersonnelPathParams

/**
 * Get all ids of facilities accessible by the given personnel.
 */
export const AmsGetFacilitiesByPersonnel = ({
  personnelId,
  ...props
}: AmsGetFacilitiesByPersonnelProps) => (
  <Get<FacilityDto[], void, void, AmsGetFacilitiesByPersonnelPathParams>
    path={`/api/v2/activitymonitoring/facilities/${personnelId}`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseAmsGetFacilitiesByPersonnelProps = Omit<
  UseGetProps<FacilityDto[], void, void, AmsGetFacilitiesByPersonnelPathParams>,
  'path'
> &
  AmsGetFacilitiesByPersonnelPathParams

/**
 * Get all ids of facilities accessible by the given personnel.
 */
export const useAmsGetFacilitiesByPersonnel = ({
  personnelId,
  ...props
}: UseAmsGetFacilitiesByPersonnelProps) =>
  useGet<FacilityDto[], void, void, AmsGetFacilitiesByPersonnelPathParams>(
    (paramsInPath: AmsGetFacilitiesByPersonnelPathParams) =>
      `/api/v2/activitymonitoring/facilities/${paramsInPath.personnelId}`,
    { base: constants.tesWebUrl, pathParams: { personnelId }, ...props }
  )

export interface AmsGetPersonsInFacilityPathParams {
  /**
   * The facility id.
   */
  facilityId: string
}

export type AmsGetPersonsInFacilityProps = Omit<
  GetProps<ActivityMonitoringPersonDto[], void, void, AmsGetPersonsInFacilityPathParams>,
  'path'
> &
  AmsGetPersonsInFacilityPathParams

/**
 * Get all persons (care recipients) in the given facility.
 */
export const AmsGetPersonsInFacility = ({ facilityId, ...props }: AmsGetPersonsInFacilityProps) => (
  <Get<ActivityMonitoringPersonDto[], void, void, AmsGetPersonsInFacilityPathParams>
    path={`/api/v2/activitymonitoring/persons/${facilityId}`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseAmsGetPersonsInFacilityProps = Omit<
  UseGetProps<ActivityMonitoringPersonDto[], void, void, AmsGetPersonsInFacilityPathParams>,
  'path'
> &
  AmsGetPersonsInFacilityPathParams

/**
 * Get all persons (care recipients) in the given facility.
 */
export const useAmsGetPersonsInFacility = ({
  facilityId,
  ...props
}: UseAmsGetPersonsInFacilityProps) =>
  useGet<ActivityMonitoringPersonDto[], void, void, AmsGetPersonsInFacilityPathParams>(
    (paramsInPath: AmsGetPersonsInFacilityPathParams) =>
      `/api/v2/activitymonitoring/persons/${paramsInPath.facilityId}`,
    { base: constants.tesWebUrl, pathParams: { facilityId }, ...props }
  )

export interface AmsGetPersonPathParams {
  /**
   * The person id.
   */
  personId: string
}

export type AmsGetPersonProps = Omit<
  GetProps<ExtendedActivityMonitoringPersonDto[], void, void, AmsGetPersonPathParams>,
  'path'
> &
  AmsGetPersonPathParams

/**
 * Get details of a person (care recipient).
 */
export const AmsGetPerson = ({ personId, ...props }: AmsGetPersonProps) => (
  <Get<ExtendedActivityMonitoringPersonDto[], void, void, AmsGetPersonPathParams>
    path={`/api/v2/activitymonitoring/person/${personId}`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseAmsGetPersonProps = Omit<
  UseGetProps<ExtendedActivityMonitoringPersonDto[], void, void, AmsGetPersonPathParams>,
  'path'
> &
  AmsGetPersonPathParams

/**
 * Get details of a person (care recipient).
 */
export const useAmsGetPerson = ({ personId, ...props }: UseAmsGetPersonProps) =>
  useGet<ExtendedActivityMonitoringPersonDto[], void, void, AmsGetPersonPathParams>(
    (paramsInPath: AmsGetPersonPathParams) =>
      `/api/v2/activitymonitoring/person/${paramsInPath.personId}`,
    { base: constants.tesWebUrl, pathParams: { personId }, ...props }
  )

export type GetDM90TokensProps = Omit<GetProps<DM90TokenDto[], unknown, void, void>, 'path'>

export const GetDM90Tokens = (props: GetDM90TokensProps) => (
  <Get<DM90TokenDto[], unknown, void, void>
    path={`/api/v2/dm90token`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetDM90TokensProps = Omit<UseGetProps<DM90TokenDto[], unknown, void, void>, 'path'>

export const useGetDM90Tokens = (props: UseGetDM90TokensProps) =>
  useGet<DM90TokenDto[], unknown, void, void>(`/api/v2/dm90token`, {
    base: constants.tesWebUrl,
    ...props,
  })

export type CreateDM90TokenProps = Omit<
  MutateProps<DM90TokenDto, string, void, CreateDM90TokenDto, void>,
  'path' | 'verb'
>

export const CreateDM90Token = (props: CreateDM90TokenProps) => (
  <Mutate<DM90TokenDto, string, void, CreateDM90TokenDto, void>
    verb="POST"
    path={`/api/v2/dm90token`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseCreateDM90TokenProps = Omit<
  UseMutateProps<DM90TokenDto, string, void, CreateDM90TokenDto, void>,
  'path' | 'verb'
>

export const useCreateDM90Token = (props: UseCreateDM90TokenProps) =>
  useMutate<DM90TokenDto, string, void, CreateDM90TokenDto, void>('POST', `/api/v2/dm90token`, {
    base: constants.tesWebUrl,
    ...props,
  })

export type DeleteDM90TokenProps = Omit<
  MutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

export const DeleteDM90Token = (props: DeleteDM90TokenProps) => (
  <Mutate<void, unknown, void, string, void>
    verb="DELETE"
    path={`/api/v2/dm90token`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseDeleteDM90TokenProps = Omit<
  UseMutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

export const useDeleteDM90Token = (props: UseDeleteDM90TokenProps) =>
  useMutate<void, unknown, void, string, void>('DELETE', `/api/v2/dm90token`, {
    base: constants.tesWebUrl,
    ...props,
  })

export interface BlockDM90TokenPathParams {
  id: string
}

export type BlockDM90TokenProps = Omit<
  MutateProps<void, string, void, void, BlockDM90TokenPathParams>,
  'path' | 'verb'
> &
  BlockDM90TokenPathParams

export const BlockDM90Token = ({ id, ...props }: BlockDM90TokenProps) => (
  <Mutate<void, string, void, void, BlockDM90TokenPathParams>
    verb="PUT"
    path={`/api/v2/dm90token/${id}/block`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseBlockDM90TokenProps = Omit<
  UseMutateProps<void, string, void, void, BlockDM90TokenPathParams>,
  'path' | 'verb'
> &
  BlockDM90TokenPathParams

export const useBlockDM90Token = ({ id, ...props }: UseBlockDM90TokenProps) =>
  useMutate<void, string, void, void, BlockDM90TokenPathParams>(
    'PUT',
    (paramsInPath: BlockDM90TokenPathParams) => `/api/v2/dm90token/${paramsInPath.id}/block`,
    { base: constants.tesWebUrl, pathParams: { id }, ...props }
  )

export interface UnblockDM90TokenPathParams {
  id: string
}

export type UnblockDM90TokenProps = Omit<
  MutateProps<void, string, void, void, UnblockDM90TokenPathParams>,
  'path' | 'verb'
> &
  UnblockDM90TokenPathParams

export const UnblockDM90Token = ({ id, ...props }: UnblockDM90TokenProps) => (
  <Mutate<void, string, void, void, UnblockDM90TokenPathParams>
    verb="PUT"
    path={`/api/v2/dm90token/${id}/unblock`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseUnblockDM90TokenProps = Omit<
  UseMutateProps<void, string, void, void, UnblockDM90TokenPathParams>,
  'path' | 'verb'
> &
  UnblockDM90TokenPathParams

export const useUnblockDM90Token = ({ id, ...props }: UseUnblockDM90TokenProps) =>
  useMutate<void, string, void, void, UnblockDM90TokenPathParams>(
    'PUT',
    (paramsInPath: UnblockDM90TokenPathParams) => `/api/v2/dm90token/${paramsInPath.id}/unblock`,
    { base: constants.tesWebUrl, pathParams: { id }, ...props }
  )

export type GetDM90TokenDepartmentsProps = Omit<
  GetProps<DM90TokenDepartmentDto[], unknown, void, void>,
  'path'
>

export const GetDM90TokenDepartments = (props: GetDM90TokenDepartmentsProps) => (
  <Get<DM90TokenDepartmentDto[], unknown, void, void>
    path={`/api/v2/dm90token/departments`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetDM90TokenDepartmentsProps = Omit<
  UseGetProps<DM90TokenDepartmentDto[], unknown, void, void>,
  'path'
>

export const useGetDM90TokenDepartments = (props: UseGetDM90TokenDepartmentsProps) =>
  useGet<DM90TokenDepartmentDto[], unknown, void, void>(`/api/v2/dm90token/departments`, {
    base: constants.tesWebUrl,
    ...props,
  })

export interface GetPersonPathParams {
  id: string
}

export type GetPersonProps = Omit<
  GetProps<PersonDto, ModelState | void, void, GetPersonPathParams>,
  'path'
> &
  GetPersonPathParams

export const GetPerson = ({ id, ...props }: GetPersonProps) => (
  <Get<PersonDto, ModelState | void, void, GetPersonPathParams>
    path={`/api/v2/person/${id}`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetPersonProps = Omit<
  UseGetProps<PersonDto, ModelState | void, void, GetPersonPathParams>,
  'path'
> &
  GetPersonPathParams

export const useGetPerson = ({ id, ...props }: UseGetPersonProps) =>
  useGet<PersonDto, ModelState | void, void, GetPersonPathParams>(
    (paramsInPath: GetPersonPathParams) => `/api/v2/person/${paramsInPath.id}`,
    { base: constants.tesWebUrl, pathParams: { id }, ...props }
  )

export interface GetAvailablePersonsInDepartmentQueryParams {
  departmentGuids?: string[]
}

export type GetAvailablePersonsInDepartmentProps = Omit<
  GetProps<PersonDto[], ModelState | void, GetAvailablePersonsInDepartmentQueryParams, void>,
  'path'
>

/**
 * Get active persons belonging to one or more departments
 */
export const GetAvailablePersonsInDepartment = (props: GetAvailablePersonsInDepartmentProps) => (
  <Get<PersonDto[], ModelState | void, GetAvailablePersonsInDepartmentQueryParams, void>
    path={`/api/v2/persons/in-departments`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetAvailablePersonsInDepartmentProps = Omit<
  UseGetProps<PersonDto[], ModelState | void, GetAvailablePersonsInDepartmentQueryParams, void>,
  'path'
>

/**
 * Get active persons belonging to one or more departments
 */
export const useGetAvailablePersonsInDepartment = (
  props: UseGetAvailablePersonsInDepartmentProps
) =>
  useGet<PersonDto[], ModelState | void, GetAvailablePersonsInDepartmentQueryParams, void>(
    `/api/v2/persons/in-departments`,
    { base: constants.tesWebUrl, ...props }
  )

export interface VerifyPersonPathParams {
  /**
   * The PersonId
   */
  personId: string
}

export type VerifyPersonProps = Omit<GetProps<void, void, void, VerifyPersonPathParams>, 'path'> &
  VerifyPersonPathParams

/**
 * Verify that a person exists.
 */
export const VerifyPerson = ({ personId, ...props }: VerifyPersonProps) => (
  <Get<void, void, void, VerifyPersonPathParams>
    path={`/api/v2/positioningservice/person/${personId}`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseVerifyPersonProps = Omit<
  UseGetProps<void, void, void, VerifyPersonPathParams>,
  'path'
> &
  VerifyPersonPathParams

/**
 * Verify that a person exists.
 */
export const useVerifyPerson = ({ personId, ...props }: UseVerifyPersonProps) =>
  useGet<void, void, void, VerifyPersonPathParams>(
    (paramsInPath: VerifyPersonPathParams) =>
      `/api/v2/positioningservice/person/${paramsInPath.personId}`,
    { base: constants.tesWebUrl, pathParams: { personId }, ...props }
  )

export interface MayPersonnelManagePersonPathParams {
  /**
   * The PersonnelId
   */
  personnelId: string
  /**
   * The PersonId
   */
  personId: string
}

export type MayPersonnelManagePersonProps = Omit<
  GetProps<void, void, void, MayPersonnelManagePersonPathParams>,
  'path'
> &
  MayPersonnelManagePersonPathParams

/**
 * Checks whether a Personnel may manage a Person regarding positioning.
 */
export const MayPersonnelManagePerson = ({
  personnelId,
  personId,
  ...props
}: MayPersonnelManagePersonProps) => (
  <Get<void, void, void, MayPersonnelManagePersonPathParams>
    path={`/api/v2/positioningservice/personnel/${personnelId}/maymanage/${personId}`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseMayPersonnelManagePersonProps = Omit<
  UseGetProps<void, void, void, MayPersonnelManagePersonPathParams>,
  'path'
> &
  MayPersonnelManagePersonPathParams

/**
 * Checks whether a Personnel may manage a Person regarding positioning.
 */
export const useMayPersonnelManagePerson = ({
  personnelId,
  personId,
  ...props
}: UseMayPersonnelManagePersonProps) =>
  useGet<void, void, void, MayPersonnelManagePersonPathParams>(
    (paramsInPath: MayPersonnelManagePersonPathParams) =>
      `/api/v2/positioningservice/personnel/${paramsInPath.personnelId}/maymanage/${paramsInPath.personId}`,
    { base: constants.tesWebUrl, pathParams: { personnelId, personId }, ...props }
  )

export type GetTimeZoneProps = Omit<GetProps<string, ModelState | void, void, void>, 'path'>

/**
 * Get time zone
 */
export const GetTimeZone = (props: GetTimeZoneProps) => (
  <Get<string, ModelState | void, void, void>
    path={`/api/v2/positioningservice/timezone`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetTimeZoneProps = Omit<UseGetProps<string, ModelState | void, void, void>, 'path'>

/**
 * Get time zone
 */
export const useGetTimeZone = (props: UseGetTimeZoneProps) =>
  useGet<string, ModelState | void, void, void>(`/api/v2/positioningservice/timezone`, {
    base: constants.tesWebUrl,
    ...props,
  })

export interface GetRtmQueryParams {
  /**
   * Status data will be retrieved from this time (rounded down to the the quarter hour).
   */
  fromTime: string
  /**
   * Status data will be retrieved up to and including this time (rounded down to the the quarter hour). If 'toTime' is null, all time window groups in the queue since 'fromTime' will be included.
   */
  toTime: string
}

export type GetRtmProps = Omit<GetProps<RtmStatusDto, ModelState, GetRtmQueryParams, void>, 'path'>

/**
 * Retrieve a collection of time window groups within a certain time span.
 */
export const GetRtm = (props: GetRtmProps) => (
  <Get<RtmStatusDto, ModelState, GetRtmQueryParams, void>
    path={`/api/v2/rtm`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetRtmProps = Omit<
  UseGetProps<RtmStatusDto, ModelState, GetRtmQueryParams, void>,
  'path'
>

/**
 * Retrieve a collection of time window groups within a certain time span.
 */
export const useGetRtm = (props: UseGetRtmProps) =>
  useGet<RtmStatusDto, ModelState, GetRtmQueryParams, void>(`/api/v2/rtm`, {
    base: constants.tesWebUrl,
    ...props,
  })

export type GetFailuresProps = Omit<
  GetProps<SupervisionFailureDto[], ModelState, void, void>,
  'path'
>

export const GetFailures = (props: GetFailuresProps) => (
  <Get<SupervisionFailureDto[], ModelState, void, void>
    path={`/api/v2/rtm/failures`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetFailuresProps = Omit<
  UseGetProps<SupervisionFailureDto[], ModelState, void, void>,
  'path'
>

export const useGetFailures = (props: UseGetFailuresProps) =>
  useGet<SupervisionFailureDto[], ModelState, void, void>(`/api/v2/rtm/failures`, {
    base: constants.tesWebUrl,
    ...props,
  })

export type GetSimpleCameraVisitsProps = Omit<
  GetProps<SimpleCameraVisitDto[], ModelState, void, void>,
  'path'
>

/**
 * Retrieve list of visits for the simplified camera visits log/table
 */
export const GetSimpleCameraVisits = (props: GetSimpleCameraVisitsProps) => (
  <Get<SimpleCameraVisitDto[], ModelState, void, void>
    path={`/api/v2/rtm/simplecameravisits`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetSimpleCameraVisitsProps = Omit<
  UseGetProps<SimpleCameraVisitDto[], ModelState, void, void>,
  'path'
>

/**
 * Retrieve list of visits for the simplified camera visits log/table
 */
export const useGetSimpleCameraVisits = (props: UseGetSimpleCameraVisitsProps) =>
  useGet<SimpleCameraVisitDto[], ModelState, void, void>(`/api/v2/rtm/simplecameravisits`, {
    base: constants.tesWebUrl,
    ...props,
  })

export type GetSystemInfoProps = Omit<GetProps<SystemInfoDto, unknown, void, void>, 'path'>

export const GetSystemInfo = (props: GetSystemInfoProps) => (
  <Get<SystemInfoDto, unknown, void, void>
    path={`/api/v2/systeminfo`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetSystemInfoProps = Omit<UseGetProps<SystemInfoDto, unknown, void, void>, 'path'>

export const useGetSystemInfo = (props: UseGetSystemInfoProps) =>
  useGet<SystemInfoDto, unknown, void, void>(`/api/v2/systeminfo`, {
    base: constants.tesWebUrl,
    ...props,
  })

export type GetUserProps = Omit<GetProps<UserDto, unknown, void, void>, 'path'>

export const GetUser = (props: GetUserProps) => (
  <Get<UserDto, unknown, void, void> path={`/api/v2/user`} base={constants.tesWebUrl} {...props} />
)

export type UseGetUserProps = Omit<UseGetProps<UserDto, unknown, void, void>, 'path'>

export const useGetUser = (props: UseGetUserProps) =>
  useGet<UserDto, unknown, void, void>(`/api/v2/user`, { base: constants.tesWebUrl, ...props })

export type ChangePasswordProps = Omit<
  MutateProps<string, unknown, void, ChangePasswordRequest, void>,
  'path' | 'verb'
>

export const ChangePassword = (props: ChangePasswordProps) => (
  <Mutate<string, unknown, void, ChangePasswordRequest, void>
    verb="POST"
    path={`/api/v2/user`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseChangePasswordProps = Omit<
  UseMutateProps<string, unknown, void, ChangePasswordRequest, void>,
  'path' | 'verb'
>

export const useChangePassword = (props: UseChangePasswordProps) =>
  useMutate<string, unknown, void, ChangePasswordRequest, void>('POST', `/api/v2/user`, {
    base: constants.tesWebUrl,
    ...props,
  })

export interface GetAvailablePersonnelInDepartmentQueryParams {
  departmentGuid?: string[]
}

export type GetAvailablePersonnelInDepartmentProps = Omit<
  GetProps<PersonnelInfo[], ModelState, GetAvailablePersonnelInDepartmentQueryParams, void>,
  'path'
>

export const GetAvailablePersonnelInDepartment = (
  props: GetAvailablePersonnelInDepartmentProps
) => (
  <Get<PersonnelInfo[], ModelState, GetAvailablePersonnelInDepartmentQueryParams, void>
    path={`/api/v2/user/mypersonnel`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetAvailablePersonnelInDepartmentProps = Omit<
  UseGetProps<PersonnelInfo[], ModelState, GetAvailablePersonnelInDepartmentQueryParams, void>,
  'path'
>

export const useGetAvailablePersonnelInDepartment = (
  props: UseGetAvailablePersonnelInDepartmentProps
) =>
  useGet<PersonnelInfo[], ModelState, GetAvailablePersonnelInDepartmentQueryParams, void>(
    `/api/v2/user/mypersonnel`,
    { base: constants.tesWebUrl, ...props }
  )

export type GetVideoCallsSettingsProps = Omit<
  GetProps<VideoCallsSettingsDto, ModelState | void, void, void>,
  'path'
>

/**
 * Get the current settings for the VideoCalls service.
 */
export const GetVideoCallsSettings = (props: GetVideoCallsSettingsProps) => (
  <Get<VideoCallsSettingsDto, ModelState | void, void, void>
    path={`/api/v2/videocallservice/settings`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetVideoCallsSettingsProps = Omit<
  UseGetProps<VideoCallsSettingsDto, ModelState | void, void, void>,
  'path'
>

/**
 * Get the current settings for the VideoCalls service.
 */
export const useGetVideoCallsSettings = (props: UseGetVideoCallsSettingsProps) =>
  useGet<VideoCallsSettingsDto, ModelState | void, void, void>(
    `/api/v2/videocallservice/settings`,
    { base: constants.tesWebUrl, ...props }
  )

export interface GetVideoCallPersonPathParams {
  /**
   * The PersonId
   */
  personid: string
}

export type GetVideoCallPersonProps = Omit<
  GetProps<VideoCallPersonDto, void, void, GetVideoCallPersonPathParams>,
  'path'
> &
  GetVideoCallPersonPathParams

/**
 * Find information about a Person.
 */
export const GetVideoCallPerson = ({ personid, ...props }: GetVideoCallPersonProps) => (
  <Get<VideoCallPersonDto, void, void, GetVideoCallPersonPathParams>
    path={`/api/v2/videocallservice/person/byid/${personid}`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetVideoCallPersonProps = Omit<
  UseGetProps<VideoCallPersonDto, void, void, GetVideoCallPersonPathParams>,
  'path'
> &
  GetVideoCallPersonPathParams

/**
 * Find information about a Person.
 */
export const useGetVideoCallPerson = ({ personid, ...props }: UseGetVideoCallPersonProps) =>
  useGet<VideoCallPersonDto, void, void, GetVideoCallPersonPathParams>(
    (paramsInPath: GetVideoCallPersonPathParams) =>
      `/api/v2/videocallservice/person/byid/${paramsInPath.personid}`,
    { base: constants.tesWebUrl, pathParams: { personid }, ...props }
  )

export interface GetVideoCallPerson2PathParams {
  /**
   * The SSN
   */
  ssn: string
}

export type GetVideoCallPerson2Props = Omit<
  GetProps<VideoCallPersonDto, void, void, GetVideoCallPerson2PathParams>,
  'path'
> &
  GetVideoCallPerson2PathParams

/**
 * Find information about a Person.
 */
export const GetVideoCallPerson2 = ({ ssn, ...props }: GetVideoCallPerson2Props) => (
  <Get<VideoCallPersonDto, void, void, GetVideoCallPerson2PathParams>
    path={`/api/v2/videocallservice/person/byssn/${ssn}`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetVideoCallPerson2Props = Omit<
  UseGetProps<VideoCallPersonDto, void, void, GetVideoCallPerson2PathParams>,
  'path'
> &
  GetVideoCallPerson2PathParams

/**
 * Find information about a Person.
 */
export const useGetVideoCallPerson2 = ({ ssn, ...props }: UseGetVideoCallPerson2Props) =>
  useGet<VideoCallPersonDto, void, void, GetVideoCallPerson2PathParams>(
    (paramsInPath: GetVideoCallPerson2PathParams) =>
      `/api/v2/videocallservice/person/byssn/${paramsInPath.ssn}`,
    { base: constants.tesWebUrl, pathParams: { ssn }, ...props }
  )

export interface GetVideoCallPersonnelPathParams {
  /**
   * The PersonnelId
   */
  personnelid: string
}

export type GetVideoCallPersonnelProps = Omit<
  GetProps<VideoCallPersonnelDto, void, void, GetVideoCallPersonnelPathParams>,
  'path'
> &
  GetVideoCallPersonnelPathParams

/**
 * Find information about a Personnel.
 */
export const GetVideoCallPersonnel = ({ personnelid, ...props }: GetVideoCallPersonnelProps) => (
  <Get<VideoCallPersonnelDto, void, void, GetVideoCallPersonnelPathParams>
    path={`/api/v2/videocallservice/personnel/byid/${personnelid}`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetVideoCallPersonnelProps = Omit<
  UseGetProps<VideoCallPersonnelDto, void, void, GetVideoCallPersonnelPathParams>,
  'path'
> &
  GetVideoCallPersonnelPathParams

/**
 * Find information about a Personnel.
 */
export const useGetVideoCallPersonnel = ({
  personnelid,
  ...props
}: UseGetVideoCallPersonnelProps) =>
  useGet<VideoCallPersonnelDto, void, void, GetVideoCallPersonnelPathParams>(
    (paramsInPath: GetVideoCallPersonnelPathParams) =>
      `/api/v2/videocallservice/personnel/byid/${paramsInPath.personnelid}`,
    { base: constants.tesWebUrl, pathParams: { personnelid }, ...props }
  )

export interface MayPersonnelManagePerson2PathParams {
  /**
   * The PersonnelId
   */
  personnelid: string
  /**
   * The PersonId
   */
  personid: string
}

export type MayPersonnelManagePerson2Props = Omit<
  GetProps<void, void, void, MayPersonnelManagePerson2PathParams>,
  'path'
> &
  MayPersonnelManagePerson2PathParams

/**
 * Checks whether a Personnel may manage a Person regarding video calls.
 */
export const MayPersonnelManagePerson2 = ({
  personnelid,
  personid,
  ...props
}: MayPersonnelManagePerson2Props) => (
  <Get<void, void, void, MayPersonnelManagePerson2PathParams>
    path={`/api/v2/videocallservice/personnel/${personnelid}/maymanage/${personid}`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseMayPersonnelManagePerson2Props = Omit<
  UseGetProps<void, void, void, MayPersonnelManagePerson2PathParams>,
  'path'
> &
  MayPersonnelManagePerson2PathParams

/**
 * Checks whether a Personnel may manage a Person regarding video calls.
 */
export const useMayPersonnelManagePerson2 = ({
  personnelid,
  personid,
  ...props
}: UseMayPersonnelManagePerson2Props) =>
  useGet<void, void, void, MayPersonnelManagePerson2PathParams>(
    (paramsInPath: MayPersonnelManagePerson2PathParams) =>
      `/api/v2/videocallservice/personnel/${paramsInPath.personnelid}/maymanage/${paramsInPath.personid}`,
    { base: constants.tesWebUrl, pathParams: { personnelid, personid }, ...props }
  )

export interface MayPersonnelCallPersonPathParams {
  /**
   * The PersonnelId
   */
  personnelid: string
  /**
   * The PersonId
   */
  personid: string
}

export type MayPersonnelCallPersonProps = Omit<
  GetProps<void, void, void, MayPersonnelCallPersonPathParams>,
  'path'
> &
  MayPersonnelCallPersonPathParams

/**
 * Checks whether a Personnel may call a Person.
 */
export const MayPersonnelCallPerson = ({
  personnelid,
  personid,
  ...props
}: MayPersonnelCallPersonProps) => (
  <Get<void, void, void, MayPersonnelCallPersonPathParams>
    path={`/api/v2/videocallservice/personnel/${personnelid}/maycall/${personid}`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseMayPersonnelCallPersonProps = Omit<
  UseGetProps<void, void, void, MayPersonnelCallPersonPathParams>,
  'path'
> &
  MayPersonnelCallPersonPathParams

/**
 * Checks whether a Personnel may call a Person.
 */
export const useMayPersonnelCallPerson = ({
  personnelid,
  personid,
  ...props
}: UseMayPersonnelCallPersonProps) =>
  useGet<void, void, void, MayPersonnelCallPersonPathParams>(
    (paramsInPath: MayPersonnelCallPersonPathParams) =>
      `/api/v2/videocallservice/personnel/${paramsInPath.personnelid}/maycall/${paramsInPath.personid}`,
    { base: constants.tesWebUrl, pathParams: { personnelid, personid }, ...props }
  )

export interface GetDepartmentsForPersonPathParams {
  /**
   * The person id
   */
  personId: string
}

export type GetDepartmentsForPersonProps = Omit<
  GetProps<string[], ModelState | void, void, GetDepartmentsForPersonPathParams>,
  'path'
> &
  GetDepartmentsForPersonPathParams

/**
 * Get the departments of a person.
 */
export const GetDepartmentsForPerson = ({ personId, ...props }: GetDepartmentsForPersonProps) => (
  <Get<string[], ModelState | void, void, GetDepartmentsForPersonPathParams>
    path={`/api/v2/videocallservice/department/forperson/${personId}`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetDepartmentsForPersonProps = Omit<
  UseGetProps<string[], ModelState | void, void, GetDepartmentsForPersonPathParams>,
  'path'
> &
  GetDepartmentsForPersonPathParams

/**
 * Get the departments of a person.
 */
export const useGetDepartmentsForPerson = ({
  personId,
  ...props
}: UseGetDepartmentsForPersonProps) =>
  useGet<string[], ModelState | void, void, GetDepartmentsForPersonPathParams>(
    (paramsInPath: GetDepartmentsForPersonPathParams) =>
      `/api/v2/videocallservice/department/forperson/${paramsInPath.personId}`,
    { base: constants.tesWebUrl, pathParams: { personId }, ...props }
  )

export interface GetDepartmentsForPersonnelMayManagePathParams {
  /**
   * The personnel id
   */
  personnelId: string
}

export type GetDepartmentsForPersonnelMayManageProps = Omit<
  GetProps<string[], ModelState | void, void, GetDepartmentsForPersonnelMayManagePathParams>,
  'path'
> &
  GetDepartmentsForPersonnelMayManagePathParams

/**
 * Get the departments where the personnel may manage persons.
 */
export const GetDepartmentsForPersonnelMayManage = ({
  personnelId,
  ...props
}: GetDepartmentsForPersonnelMayManageProps) => (
  <Get<string[], ModelState | void, void, GetDepartmentsForPersonnelMayManagePathParams>
    path={`/api/v2/videocallservice/department/forpersonnel/${personnelId}/maymanage`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetDepartmentsForPersonnelMayManageProps = Omit<
  UseGetProps<string[], ModelState | void, void, GetDepartmentsForPersonnelMayManagePathParams>,
  'path'
> &
  GetDepartmentsForPersonnelMayManagePathParams

/**
 * Get the departments where the personnel may manage persons.
 */
export const useGetDepartmentsForPersonnelMayManage = ({
  personnelId,
  ...props
}: UseGetDepartmentsForPersonnelMayManageProps) =>
  useGet<string[], ModelState | void, void, GetDepartmentsForPersonnelMayManagePathParams>(
    (paramsInPath: GetDepartmentsForPersonnelMayManagePathParams) =>
      `/api/v2/videocallservice/department/forpersonnel/${paramsInPath.personnelId}/maymanage`,
    { base: constants.tesWebUrl, pathParams: { personnelId }, ...props }
  )

export interface GetDepartmentsForPersonnelMayCallPathParams {
  /**
   * The personnel id
   */
  personnelId: string
}

export type GetDepartmentsForPersonnelMayCallProps = Omit<
  GetProps<string[], ModelState | void, void, GetDepartmentsForPersonnelMayCallPathParams>,
  'path'
> &
  GetDepartmentsForPersonnelMayCallPathParams

/**
 * Get the departments where the personnel may call persons.
 */
export const GetDepartmentsForPersonnelMayCall = ({
  personnelId,
  ...props
}: GetDepartmentsForPersonnelMayCallProps) => (
  <Get<string[], ModelState | void, void, GetDepartmentsForPersonnelMayCallPathParams>
    path={`/api/v2/videocallservice/department/forpersonnel/${personnelId}/maycall`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetDepartmentsForPersonnelMayCallProps = Omit<
  UseGetProps<string[], ModelState | void, void, GetDepartmentsForPersonnelMayCallPathParams>,
  'path'
> &
  GetDepartmentsForPersonnelMayCallPathParams

/**
 * Get the departments where the personnel may call persons.
 */
export const useGetDepartmentsForPersonnelMayCall = ({
  personnelId,
  ...props
}: UseGetDepartmentsForPersonnelMayCallProps) =>
  useGet<string[], ModelState | void, void, GetDepartmentsForPersonnelMayCallPathParams>(
    (paramsInPath: GetDepartmentsForPersonnelMayCallPathParams) =>
      `/api/v2/videocallservice/department/forpersonnel/${paramsInPath.personnelId}/maycall`,
    { base: constants.tesWebUrl, pathParams: { personnelId }, ...props }
  )

export interface GetPersonsInDepartmentPathParams {
  /**
   * The department id
   */
  departmentId: string
}

export type GetPersonsInDepartmentProps = Omit<
  GetProps<string[], ModelState | void, void, GetPersonsInDepartmentPathParams>,
  'path'
> &
  GetPersonsInDepartmentPathParams

/**
 * Get the persons in a department.
 */
export const GetPersonsInDepartment = ({ departmentId, ...props }: GetPersonsInDepartmentProps) => (
  <Get<string[], ModelState | void, void, GetPersonsInDepartmentPathParams>
    path={`/api/v2/videocallservice/person/indepartment/${departmentId}`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetPersonsInDepartmentProps = Omit<
  UseGetProps<string[], ModelState | void, void, GetPersonsInDepartmentPathParams>,
  'path'
> &
  GetPersonsInDepartmentPathParams

/**
 * Get the persons in a department.
 */
export const useGetPersonsInDepartment = ({
  departmentId,
  ...props
}: UseGetPersonsInDepartmentProps) =>
  useGet<string[], ModelState | void, void, GetPersonsInDepartmentPathParams>(
    (paramsInPath: GetPersonsInDepartmentPathParams) =>
      `/api/v2/videocallservice/person/indepartment/${paramsInPath.departmentId}`,
    { base: constants.tesWebUrl, pathParams: { departmentId }, ...props }
  )

export interface GetPersonnelInDepartmentMayManagePathParams {
  /**
   * The department id
   */
  departmentId: string
}

export type GetPersonnelInDepartmentMayManageProps = Omit<
  GetProps<string[], ModelState | void, void, GetPersonnelInDepartmentMayManagePathParams>,
  'path'
> &
  GetPersonnelInDepartmentMayManagePathParams

/**
 * Get the personnel allowed to call persons in a department.
 */
export const GetPersonnelInDepartmentMayManage = ({
  departmentId,
  ...props
}: GetPersonnelInDepartmentMayManageProps) => (
  <Get<string[], ModelState | void, void, GetPersonnelInDepartmentMayManagePathParams>
    path={`/api/v2/videocallservice/personnel/indepartment/${departmentId}/maymanage`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetPersonnelInDepartmentMayManageProps = Omit<
  UseGetProps<string[], ModelState | void, void, GetPersonnelInDepartmentMayManagePathParams>,
  'path'
> &
  GetPersonnelInDepartmentMayManagePathParams

/**
 * Get the personnel allowed to call persons in a department.
 */
export const useGetPersonnelInDepartmentMayManage = ({
  departmentId,
  ...props
}: UseGetPersonnelInDepartmentMayManageProps) =>
  useGet<string[], ModelState | void, void, GetPersonnelInDepartmentMayManagePathParams>(
    (paramsInPath: GetPersonnelInDepartmentMayManagePathParams) =>
      `/api/v2/videocallservice/personnel/indepartment/${paramsInPath.departmentId}/maymanage`,
    { base: constants.tesWebUrl, pathParams: { departmentId }, ...props }
  )

export interface GetPersonnelInDepartmentMayCallPathParams {
  /**
   * The department id
   */
  departmentId: string
}

export type GetPersonnelInDepartmentMayCallProps = Omit<
  GetProps<string[], ModelState | void, void, GetPersonnelInDepartmentMayCallPathParams>,
  'path'
> &
  GetPersonnelInDepartmentMayCallPathParams

/**
 * Get the personnel allowed to manage persons in a department.
 */
export const GetPersonnelInDepartmentMayCall = ({
  departmentId,
  ...props
}: GetPersonnelInDepartmentMayCallProps) => (
  <Get<string[], ModelState | void, void, GetPersonnelInDepartmentMayCallPathParams>
    path={`/api/v2/videocallservice/personnel/indepartment/${departmentId}/maycall`}
    base={constants.tesWebUrl}
    {...props}
  />
)

export type UseGetPersonnelInDepartmentMayCallProps = Omit<
  UseGetProps<string[], ModelState | void, void, GetPersonnelInDepartmentMayCallPathParams>,
  'path'
> &
  GetPersonnelInDepartmentMayCallPathParams

/**
 * Get the personnel allowed to manage persons in a department.
 */
export const useGetPersonnelInDepartmentMayCall = ({
  departmentId,
  ...props
}: UseGetPersonnelInDepartmentMayCallProps) =>
  useGet<string[], ModelState | void, void, GetPersonnelInDepartmentMayCallPathParams>(
    (paramsInPath: GetPersonnelInDepartmentMayCallPathParams) =>
      `/api/v2/videocallservice/personnel/indepartment/${paramsInPath.departmentId}/maycall`,
    { base: constants.tesWebUrl, pathParams: { departmentId }, ...props }
  )
