import PrivateRoute from 'components/PrivateRoute/PrivateRoute'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import HomePage from '../pages/HomePage/HomePage'
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage'
import { routes } from './routes'

const Router = () => (
  <Switch>
    <PrivateRoute exact path={routes.home} component={HomePage} />
    <Route path="*" component={NotFoundPage} />
  </Switch>
)

export default Router
