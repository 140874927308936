import React, { useCallback } from 'react'
import { constants } from 'config/constants'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { Toolbar } from '@material-ui/core'
import { CssBaseline } from 'tunstall-ui/core'
import { useStyles } from 'App.styles'
import Router from 'routes/Router'
import { RestfulProvider } from 'restful-react'
import ContextProviders from 'contexts/ContextProviders'
import { GlobalCSS } from 'tunstall-ui/components'

const App = () => {
  const classes = useStyles()

  const requestOptions = useCallback(
    () => ({
      headers: { Authorization: `Bearer ${constants.authToken}` },
    }),
    []
  )

  return (
    <RestfulProvider base={constants.tesWebUrl} requestOptions={requestOptions}>
      <CssBaseline />
      <GlobalCSS />
      <ContextProviders>
        <div className={classes.root}>
          <main className={classes.content}>
            <Toolbar />
            <ErrorBoundary>
              <Router />
            </ErrorBoundary>
          </main>
        </div>
      </ContextProviders>
    </RestfulProvider>
  )
}

export default App
