import React from 'react'
import { useDeleteDM90Token } from 'generated/tesApi'
import { Modal, Button } from 'tunstall-ui/components'
import { DeleteIcon } from 'tunstall-ui/icons'
import { useTranslation } from 'react-i18next'
import { useStyles } from './DeleteTokenModal.styles'

interface DeleteTokenModalProps {
  onClose: () => void
  onDeleted: () => void
  identifier: string
}

const DeleteTokenModal = ({ onClose, onDeleted, identifier }: DeleteTokenModalProps) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { mutate: deleteDM90Token } = useDeleteDM90Token({})

  const deleteIconElement = React.createElement(DeleteIcon, { width: 22, height: 22 })

  return (
    <Modal
      titleIcon={deleteIconElement}
      className={classes.modal}
      width="small"
      noPadding
      open
      title={t('dm90page.deleteTokenModal.modalTitle', 'Delete')}
      actions={
        <div>
          <Button variant="text" onClick={onClose} data-testid="cancel-button">
            {t('dm90page.createTokenModal.closeButtonLabel', 'Cancel')}
          </Button>
          <Button
            variant="text"
            onClick={() => {
              deleteDM90Token(identifier).then(() => {
                onClose()
                onDeleted()
              })
            }}
          >
            {t('dm90page.deleteTokenModal.deleteButtonLabel', 'Delete')}
          </Button>
        </div>
      }
      onClose={onClose}
    >
      <div className={classes.container}>
        {t(
          'dm90page.deleteTokenModal.finalCheckButtonLabel',
          'Are you sure you would like to delete this token?'
        )}
      </div>
    </Modal>
  )
}

export default React.memo(DeleteTokenModal)
