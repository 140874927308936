import { getCookie } from 'utils/getCookie'

export const envVars = {
  environment: process.env.NODE_ENV,
  SHOULD_MOCK_URLS: process.env.REACT_APP_SHOULD_MOCK_URLS === 'true' ?? false,
  SHOULD_REDIRECT_TO_LOGIN: process.env.REACT_APP_SHOULD_REDIRECT_TO_LOGIN === 'true' ?? true,
}

export const constants = {
  authToken: getCookie('Bearer'),
  tesWebUrl: getCookie('TesWebUrl'),
}

if (envVars.SHOULD_MOCK_URLS) {
  if (!constants.tesWebUrl) {
    document.cookie = 'TesWebUrl=https://localhost'
    constants.tesWebUrl = getCookie('TesWebUrl')
  }
}
