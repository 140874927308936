import React, { useState } from 'react'
import { useCreateDM90Token, useGetDM90TokenDepartments } from 'generated/tesApi'
import { Modal, Button, TextInput, Select } from 'tunstall-ui/components'
import { PlusIcon } from 'tunstall-ui/icons'
import { useTranslation } from 'react-i18next'
import { useStyles } from './CreateTokenModal.styles'

interface CreateTokenModalProps {
  onClose: () => void
  onCreated: () => void
}

const CreateTokenModal = ({ onClose, onCreated }: CreateTokenModalProps) => {
  const { t } = useTranslation()
  const [nameValue, setName] = useState('')
  const [nameError, setNameError] = useState(false)
  const [identifierValue, setIdentifier] = useState('')
  const [identifierError, setIdentifierError] = useState(false)
  const [departmentValue, setDepartment] = useState('')
  const [serverResponse, setServerResponse] = useState('')
  const [showServerResponse, setShowServerResponse] = useState(false)
  const classes = useStyles()

  const { mutate: insertDM90Token } = useCreateDM90Token({})
  const { data: dm90TokenDepartments } = useGetDM90TokenDepartments({})
  const dm90TokenDepartmentOptions = (
    (dm90TokenDepartments?.map && dm90TokenDepartments) ||
    []
  )?.map((e) => new Option(e.name, e.id))

  const validateNameValue = () => {
    setNameError(false)
    if (nameValue === '') {
      setNameError(true)
      return false
    }

    return true
  }

  const validateIdentifierValue = () => {
    setIdentifierError(false)
    const pattern = new RegExp(
      '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$',
      'i'
    )
    if (pattern.test(identifierValue) !== true) {
      setIdentifierError(true)
      return false
    }

    return true
  }

  const createNewToken = (name: string, identifier: string, departmentId: string) => {
    setShowServerResponse(false)
    const identifierValidation = validateIdentifierValue()
    const nameValidation = validateNameValue()

    if (identifierValidation && nameValidation) {
      insertDM90Token({ name, identifier, departmentId })
        .then(() => {
          onCreated()
          onClose()
        })
        .catch((error) => {
          const { data } = error
          setServerResponse(data.message)
          setShowServerResponse(true)
        })
    }
  }

  const plusIconElement = React.createElement(PlusIcon, { width: 22, height: 22 })

  const ServerResponse = (props: { showResponse: boolean }) => {
    const { showResponse } = props
    if (showResponse) {
      return (
        <li className={classes.serverResponseContainer}>
          <div className={classes.serverResponse}>{serverResponse}</div>
        </li>
      )
    }
    return null
  }

  return (
    <Modal
      titleIcon={plusIconElement}
      className={classes.modal}
      width="small"
      noPadding
      open
      title={t('dm90page.createTokenModal.modalTitle', 'Add new DM90 Token')}
      actions={
        <div>
          <Button variant="text" onClick={onClose} data-testid="cancel-button">
            {t('dm90page.createTokenModal.closeButtonLabel', 'Cancel')}
          </Button>
          <Button
            variant="text"
            onClick={() => {
              createNewToken(nameValue, identifierValue, departmentValue)
            }}
          >
            {t('dm90page.createTokenModal.addButtonLabel', 'Add')}
          </Button>
        </div>
      }
      onClose={onClose}
    >
      <div className={classes.container}>
        <ul className={classes.formContainer}>
          <li>
            <TextInput
              required
              variant="outlined"
              fullWidth
              label={t('dm90page.createTokenModal.nameFieldLabel', 'Name')}
              value={nameValue}
              error={nameError}
              helperText={
                nameError ? t('dm90page.createTokenModal.nameError', 'Please enter name') : ''
              }
              onChange={(e) => setName(e.target.value)}
            />
          </li>
          <li>
            <TextInput
              required
              variant="outlined"
              fullWidth
              label={t('dm90page.createTokenModal.identifierFieldLabel', 'Identifier')}
              value={identifierValue}
              error={identifierError}
              helperText={
                identifierError
                  ? t('dm90page.createTokenModal.identifierError', 'Please enter valid GUID')
                  : ''
              }
              onChange={(e) => setIdentifier(e.target.value)}
            />
          </li>
          <li>
            <Select
              required
              variant="outlined"
              fullWidth
              label={t('dm90page.createTokenModal.departmentFieldLabel', 'Department')}
              options={dm90TokenDepartmentOptions}
              onChange={(e) => setDepartment(e.target.value as string)}
            />
          </li>
          <ServerResponse showResponse={showServerResponse} />
        </ul>
      </div>
    </Modal>
  )
}

export default React.memo(CreateTokenModal)
