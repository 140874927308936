import {
  useGetDM90Tokens,
  useBlockDM90Token,
  useUnblockDM90Token,
  DM90TokenDto,
} from 'generated/tesApi'
import React, { useState, useMemo } from 'react'
import {
  LockedFilledIcon,
  KeyIconFilled,
  NavigationMoreVerticalIcon,
  PlusIcon,
} from 'tunstall-ui/icons'
import i18n from 'i18n'
import { colors } from 'tunstall-ui/core/variables'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import DisplayTokenModal from 'components/DisplayTokenModal/DisplayTokenModal'
import { IconButton, Button, Card, Menu, MenuItem, Typography, Table } from 'tunstall-ui/components'
import { ColumnType } from 'tunstall-ui/components/Table/HeaderRow/ColumnHeader/ColumnHeader'
import CreateTokenModal from 'components/CreateTokenModal/CreateTokenModal'
import DeleteTokenModal from 'components/DeleteTokenModal/DeleteTokenModal'
import { useStyles } from './HomePage.styles'

const columnData = [
  {
    Header: i18n.t('HomePage.tableColumn.Name', 'Name'),
    accessor: 'name',
    type: ColumnType.Filter,
  },
  {
    Header: i18n.t('HomePage.tableColumn.departmentId', 'Department Name'),
    accessor: 'departmentName',
    type: ColumnType.Filter,
  },
  {
    Header: i18n.t('HomePage.tableColumn.Identifier', 'Identifier'),
    accessor: 'identifier',
    type: ColumnType.Filter,
  },
  {
    Header: i18n.t('HomePage.tableColumn.createdAt', 'Created At'),
    accessor: 'createdAt',
    type: ColumnType.Sort,
    sortInverted: true,
  },
  {
    Header: i18n.t('HomePage.tableColumn.blockedDate', 'Blocked Date'),
    accessor: 'blockedDate',
    type: ColumnType.Sort,
  },
  {
    Header: i18n.t('HomePage.tableColumn.token', 'Token'),
    accessor: 'token',
    type: ColumnType.None,
  },
  {
    Header: i18n.t('HomePage.tableColumn.blocked', 'Blocked'),
    accessor: 'blocked',
    type: ColumnType.None,
  },
  {
    Header: '',
    accessor: 'modify',
    type: ColumnType.None,
  },
]

const formatDateString = (date: string) => format(new Date(date), 'yyyy-MM-dd, HH:mm')

const HomePage = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [tokenToDisplay, setTokenToDisplay] = useState('')
  const [tokenToDelete, setTokenToDelete] = useState('')
  const [insertingNewToken, setInsertingNewToken] = useState(false)
  const [menuRef, setMenuRef] = useState<{
    ref: HTMLElement
    tokenObject: DM90TokenDto
  } | null>()

  const {
    data: departmentTokens,
    loading: loadingTokens,
    refetch: refetchTokens,
  } = useGetDM90Tokens({})

  const { mutate: mutateBlockToken, loading: blockingToken } = useBlockDM90Token({
    id: menuRef?.tokenObject?.id || '',
  })
  const { mutate: mutateUnblockToken, loading: unblockingToken } = useUnblockDM90Token({
    id: menuRef?.tokenObject?.id || '',
  })

  const updating = loadingTokens || blockingToken || unblockingToken

  const formattedDepartmentTokens = useMemo(() => {
    if (departmentTokens?.map) {
      return departmentTokens.map((departmentToken) => ({
        name: <span>{departmentToken.name}</span>,
        departmentName: <span>{departmentToken.departmentName || ''}</span>,
        identifier: <span>{departmentToken.identifier}</span>,
        createdAt: (
          <span>
            {departmentToken.createdDate && formatDateString(departmentToken.createdDate)}
          </span>
        ),
        blockedDate: (
          <span>
            {(departmentToken.blockedDate && formatDateString(departmentToken.blockedDate)) || ''}
          </span>
        ),
        lastConnectedDate: (
          <span>
            {departmentToken.lastConnectedDate &&
              formatDateString(departmentToken.lastConnectedDate)}
          </span>
        ),
        token: (
          <span>
            <IconButton
              bgColor="transparent"
              contentColor="azure0"
              shadow={false}
              onClick={() => {
                setTokenToDisplay(departmentToken.token || '')
              }}
            >
              <KeyIconFilled width={16} height={16} />
            </IconButton>
          </span>
        ),
        blocked: (
          <span>
            {departmentToken.blockedDate ? <LockedFilledIcon width={16} height={16} /> : ''}
          </span>
        ),
        modify: (
          <div>
            <IconButton
              bgColor="transparent"
              contentColor="azure0"
              shadow={false}
              onClick={(event) => {
                setMenuRef({ ref: event.currentTarget, tokenObject: departmentToken })
              }}
            >
              <NavigationMoreVerticalIcon width={16} height={16} />
            </IconButton>
          </div>
        ),
        rowColor: colors.white,
      }))
    }
    return []
  }, [departmentTokens, setMenuRef])

  return (
    <div>
      <div>
        {menuRef && !updating && (
          <Menu keepMounted open onClose={() => setMenuRef(null)} anchorEl={menuRef.ref}>
            <MenuItem
              onClick={() => {
                setTokenToDelete(menuRef.tokenObject.id)
              }}
            >
              {t('dm90page.homepage.removeButtonLabel', 'Remove')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (menuRef.tokenObject.blockedDate) {
                  mutateUnblockToken().then(() => {
                    refetchTokens()
                    setMenuRef(null)
                  })
                } else {
                  mutateBlockToken().then(() => {
                    refetchTokens()
                    setMenuRef(null)
                  })
                }
              }}
            >
              {menuRef?.tokenObject?.blockedDate
                ? t('dm90page.homepage.unblockButtonLabel', 'Unblock')
                : t('dm90page.homepage.blockButtonLabel', 'Block')}
            </MenuItem>
          </Menu>
        )}
        {insertingNewToken && !updating && (
          <CreateTokenModal
            onClose={() => {
              setInsertingNewToken(false)
            }}
            onCreated={() => {
              refetchTokens()
            }}
          />
        )}
        {tokenToDisplay && !updating && (
          <DisplayTokenModal
            token={tokenToDisplay}
            onClose={() => {
              setTokenToDisplay('')
            }}
          />
        )}
        {tokenToDelete && !updating && (
          <DeleteTokenModal
            identifier={tokenToDelete}
            onClose={() => {
              setTokenToDelete('')
            }}
            onDeleted={() => {
              refetchTokens()
              setMenuRef(null)
            }}
          />
        )}
      </div>
      <div className={classes.pageWrapper}>
        <Card
          className={classes.card}
          title={
            <div className={classes.cardTitle}>
              <Typography variant="h2" className={classes.title}>
                {t('dm90page.homepage.title', 'DM90 Tokens')}
              </Typography>
            </div>
          }
        >
          <div className={classes.menuBar}>
            <div className={classes.menuBarRightSide}>
              <Button
                data-testid="add-button"
                variant="contained"
                onClick={() => {
                  setInsertingNewToken(true)
                }}
              >
                <div>
                  <div className={classes.plusIcon}>
                    <PlusIcon height={8} width={8} htmlColor="white" />
                  </div>
                  {t('dm90page.homepage.addNewButtonLabel', 'Add new')}
                </div>
              </Button>
            </div>
          </div>
          <Table
            globalSearchFilterLabel={t('dm90page.homepage.searchFilterLabel', 'Search')}
            columnClearFilterLabel={t('dm90page.homepage.clearFilterLabel', 'Clear')}
            contextMenuLabel={t('dm90page.homepage.filterByLabel', 'Filter By')}
            hideTitleRow
            columns={columnData}
            rows={formattedDepartmentTokens ?? []}
          />
        </Card>
      </div>
    </div>
  )
}

export default HomePage
