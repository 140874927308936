import React from 'react'
import { Copyable, Modal, Button, TextInput } from 'tunstall-ui/components'
import { useTranslation } from 'react-i18next'
import { KeyIconFilled } from 'tunstall-ui/icons'
import { useStyles } from './DisplayTokenModal.styles'

interface HistoryModalProps {
  onClose: () => void
  token: string
}

const DisplayTokenModal = ({ onClose, token }: HistoryModalProps) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const keyIconFilledElement = React.createElement(KeyIconFilled, { width: 22, height: 22 })

  return (
    <Modal
      titleIcon={keyIconFilledElement}
      className={classes.modal}
      width="medium"
      noPadding
      open={Boolean(token)}
      title={t('dm90page.displayTokenModal.modalTitle', 'Token')}
      actions={
        <Button variant="text" onClick={onClose} data-testid="close-button">
          {t('dm90page.displayTokenModal.closeButtonLabel', 'Close')}
        </Button>
      }
      onClose={onClose}
    >
      <div className={classes.container}>
        <TextInput
          label={t('dm90page.displayTokenModal.nameFieldLabel', 'Name')}
          value={token}
          multiline
          fullWidth
        />
        <div className={classes.copyButton}>
          <Copyable
            onClick={() => {
              navigator.clipboard.writeText(token)
            }}
            text={t('dm90page.displayTokenModal.copyButtonLabel', 'Copy')}
          />
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(DisplayTokenModal)
