import { constants, envVars } from 'config/constants'
import React, { useEffect } from 'react'
import { Route, RouteProps, useHistory } from 'react-router-dom'

interface PrivateRouteProps extends RouteProps {}

const PrivateRoute = ({ children, ...otherProps }: PrivateRouteProps) => {
  // TODO: replace with logic to actually validate token, with jwt.validate
  const isAuthenticated = Boolean(constants.authToken)
  const history = useHistory()

  useEffect(() => {
    if (!isAuthenticated && envVars.SHOULD_REDIRECT_TO_LOGIN) {
      // eslint-disable-next-line no-restricted-globals
      location.href = constants.tesWebUrl
    }
  }, [isAuthenticated, history])

  return isAuthenticated ? <Route {...otherProps}>{children}</Route> : <>Not logged in</>
}

export default PrivateRoute
