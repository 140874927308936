import React from 'react'
import ReactDOM from 'react-dom'
import PrivateRoute from 'components/PrivateRoute/PrivateRoute'
import { HashRouter, BrowserRouter } from 'react-router-dom'
import './i18n'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'fontsource-roboto'

// Switch to HashRouter for pull requests because serving static files directly doesn't work with BrowserRouter
const Router: typeof BrowserRouter = process.env.REACT_APP_IS_PULL_REQUEST
  ? HashRouter
  : BrowserRouter

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <PrivateRoute>
        <App />
      </PrivateRoute>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
