import React from 'react'
import { GlobalStateContextProvider } from './GlobalStateContext'

interface ContextProvidersProps {
  children?: React.ReactNode
}

const ContextProviders = ({ children }: ContextProvidersProps) => (
  <GlobalStateContextProvider>{children}</GlobalStateContextProvider>
)

export default ContextProviders
