import { makeStyles } from '@material-ui/core'
import { colors, spacing } from 'tunstall-ui/core/variables'

export const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: spacing(4),
  },
  taskItem: {
    backgroundColor: colors.bgIceBlue,
    padding: spacing(2),
    marginBottom: spacing(2),
  },
  card: {
    height: '100%',
    '& tr': {
      transition: 'background-color 750ms ease-out',
    },
  },
  cardTitle: {
    position: 'relative',
  },
  title: {
    margin: `${spacing(4)} 0 ${spacing(3)} ${spacing(7)}`,
    fontFamily: 'Work Sans',
    fontSize: '18px',
    fontWeight: 500,
    letterSpacing: '-0.81px',
    color: '#1a1a1a',
    lineHeight: 'normal',
  },
  sidebarWrapper: {
    zIndex: 1000,
    backgroundColor: 'white',
    width: '11.5%',
    position: 'fixed',
    left: '11.5%',
    height: '100%',
    marginLeft: '-11.5%',
    padding: '44px 15px 0',
    top: '51px',
    boxShadow: '2px 0 6px 0 rgb(0 0 0 / 9%)',
    paddingLeft: 0,
    borderRadius: '5px',
  },
  pageWrapper: {
    width: '100%',
  },
  menuBar: {
    width: '100%',
    padding: '10px',
  },
  menuBarRightSide: {
    float: 'right',
  },
  plusIcon: {
    marginRight: '5px',
    float: 'left',
  },
}))
