import makeStyles from '@material-ui/styles/makeStyles'
import { spacing, colors } from 'tunstall-ui/core/variables'

export const useStyles = makeStyles(() => ({
  modal: {
    '& .MuiDialog-paper': {
      height: '100%',
      '& .MuiDialogTitle-root': {
        borderBottom: `1px solid ${colors.battleshipGreyT20}`,
      },
    },
  },
  container: {
    padding: spacing(6),
    '& ul li': {
      width: '100%',
      listStyleType: 'none',
      padding: spacing(1),
    },
  },
  copyButton: {
    float: 'right',
  },
  formContainer: {
    margin: 0,
  },
}))
